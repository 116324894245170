@charset "utf-8";

@font-face {font-family: 'Circe'; src: url('/fonts/Circe-Regular.eot'); src: local('Circe'), local('Circe-Regular'), url('/fonts/Circe-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/Circe-Regular.woff') format('woff'), url('/fonts/Circe-Regular.ttf') format('truetype'); font-weight: 400; font-style: normal; }
@font-face {font-family: 'Circe'; src: url('/fonts/Circe-Bold.eot'); src: local('Circe Bold'), local('Circe-Bold'), url('/fonts/Circe-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/Circe-Bold.woff') format('woff'), url('/fonts/Circe-Bold.ttf') format('truetype'); font-weight: 600; font-style: normal; }
@font-face {font-family: 'icons'; src:  url('/fonts/icons.eot?c2qna0'); src:  url('/fonts/icons.eot?c2qna0#iefix') format('embedded-opentype'), url('/fonts/icons.ttf?c2qna0') format('truetype'), url('/fonts/icons.woff?c2qna0') format('woff'), url('/fonts/icons.svg?c2qna0#icons') format('svg'); font-weight: normal; font-style: normal; font-display: block; }
html, body{ min-width: 294px;  height: 100%; }
a, .link { color: #6d6e71; text-decoration: none; cursor: pointer; border-bottom: 1px solid;transition: 300ms; }
@media (min-width: 768px) {
    a:hover, .link:hover { border-bottom-color: transparent; }
}
.link{color: #f15f22; line-height: 1;}
a img{ border:none; }
*{ outline: none; }
input, button { -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-box-shadow: none; box-shadow: none; }
b{ font-weight: 600 }

/*icons*/
[class^="ico-"]:before, [class*=" ico-"]:before, .select-field:after {font-family: 'icons' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.ico-1:before { content: "\e900"; }
.ico-2:before, .ico-email:before { content: "\e901"; font-size: 13px; }
.ico-3:before, .ico-password:before { content: "\e902"; font-size: 9px; }
.ico-4:before, .ico-close:before {content: "\e903"; }
.ico-5:before, .ico-faq:before {content: "\e904"; font-size: 18px; }
.ico-6:before, .ico-profile:before {content: "\e905"; font-size: 17px;}
.ico-7:before, .ico-fav:before {content: "\e906"; font-size: 14px; }
.ico-8:before, .ico-lock:before {content: "\e907"; font-size: 18px; }
.ico-9:before, .ico-cart:before {content: "\e908"; font-size: 22px; }
.ico-10:before, .ico-download:before {content: "\e909"; }
.ico-11:before, .open .ico-password:before {content: "\e90a"; font-size: 13px;}
.ico-12:before, .ico-arrow-bottom:before, .select-field:after{content: "\e90b"; font-size: 8px;}
.ico-13:before, .ico-change-email:before{content: "\e90c"; font-size: 13px;}
.ico-14:before{content: "\e90d";}
.ico-15:before{content: "\e90e";}
.ico-16:before{content: "\e90f"; font-size: 35px;}
.ico-17:before{content: "\e910"; font-size: 35px;}
.ico-18:before{content: "\e911"; font-size: 35px;}
.ico-19:before{content: "\e912"; font-size: 20px; top: -2px; position: relative;}
.ico-20:before, .ico-logout:before {content: "\e913"; font-size: 22px; }
.ico-21:before, .ico-ok:before {content: "\e914";}
.ico-22:before {content: "\e915";}

/*animation*/
[class^="animate-"], [class*=" animate-"], .header, .middle {
    transition: 1000ms transform, 1000ms opacity;
}
.animate-left{
    transform: translateX(-100px);
    opacity: 0;
}
.middle {
    /*transform: translateY(20px);*/
    opacity: 0;
}
.animate-scale{
    transform: scale(1.2);
    opacity: 0;
}
.animate-fade, .header{
    opacity: 0;
}
.loaded [class^="animate-"], .loaded [class*=" animate-"],
.loaded .middle,
.loaded .header {
    transform: translate(0);
    opacity: 1;
}
.animate-delay01{
    transition-delay: .1s;
}
.animate-delay02{
    transition-delay: .3s;
}
.animate-delay03{
    transition-delay: .3s;
}

/*loader*/

.loading-wrap{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.5);
}
.loading{
    position: fixed;
    top: 50%;
    left: 50%;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    transform: translate(-50%, -50%);
    background: #fff;
    font-family: Arial;
    letter-spacing: 1px;
}
.loader{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    animation: loading 1.5s;
    height: 100%;
    width: 100%;
    z-index: 100;
    background: rgba(255,255,255,.5)
}
@keyframes loading {
    0% {transform: translate(-50%, -50%) scale(1); opacity:0;  }
    100% { transform: translate(-50%, -50%) scale(1.2); opacity: 1 ; }
}
.img-loader{width: 24px;}

.loading img{max-width: 100px;}
/*.loaded .loader{
    visibility: hidden;
    animation-play-state: paused;
}*/


body {
    margin: 0;
    background: transparent;
    font-family: 'Circe';
    font-weight: 400;
    line-height: 1.2;
    font-size: 16px;
    color: #6d6e71;
    position: relative;
}

#root{
    min-height: 100%;
    position: relative;
    overflow: hidden;
}

.page-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/images/bg.jpg);
    background-position: 50% 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.auth-logo{
    position: fixed;
    top: 5vw;
    left: 5.6vw;
    color: #fff;
}
.auth-logo:before{
    font-size: 69px;
}
@media (max-width: 1000px),
(max-width: 1100px) and (max-height: 700px) {
    .auth-logo{
        position: absolute;
        left: 50%;
        top: 35px;
        margin-left: -78px;
    }

}
.modal{
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
}
.hidden{
    display: none;
}
.modal-close{
    position: absolute;
    top: 0;
    right: 0;
    width: 72px;
    height: 72px;
    cursor: pointer;
    transition: 600ms;
}
@media (max-width: 990px) {
    .modal-close{
        width: 60px;
        height: 60px;
    }

}
.modal-close:before{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #f15f21;
    font-size: 14px;
}
@media (min-width: 768px) {
    .modal-close:hover{
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }
}
.modal__overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(109,110,113,.5);
    opacity: 0;
    transition: 600ms;
}
.modal.visible .modal__overlay{
    opacity: 1;
}
.modal__container{
    width: 500px;
    max-height: 100%;
    position: relative;
    opacity: 0;
    -webkit-transform: translateY(80px);
    -ms-transform: translateY(80px);
    -o-transform: translateY(80px);
    transform: translateY(80px);
    transition: 600ms;
}
.modal.visible .modal__container{
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.modal__body{
    background: #fff;
    margin-top: 30px;
    position: relative;
    padding: 80px 35px 65px;
}
@media (max-width: 540px),
(max-width: 1100px) and (max-height: 800px)  {
    .modal__body {
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 150px;
        /*padding: 45px 15px;*/
        padding: 14vmin 4.2vmin;
    }
    .modal-profile-form .modal__body,
    .modal-email-message .modal__body,
    .modal-reservation-message .modal__body,
    .modal-ask-question .modal__body {
        margin-top: 30px;
    }
}
.modal-helper{
    height: 30px;
}
.modal-title{
    font-size: 28px;
    color: #6d6e71;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin-bottom: 35px;
}
@media (max-width: 540px) {
    .modal-title{
        font-size: 24px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (max-width: 370px) {
    .modal-title{
        font-size: 20px;
        margin-bottom: 25px;
    }
}
.form-content{
    padding: 0 55px;
}
@media (max-width: 540px) {
    .form-content{
        padding: 0 30px;
    }
}
@media (max-width: 370px) {
    .form-content{
        padding: 0 20px;
    }
}
.color-orange{
    color: #f15f21;
}
.modal-text {
    margin-bottom: 24px;
}
.modal-reset-password-message .modal-text {
    max-width: 300px;
}
.modal-text--bottom{
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
    padding-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
    max-width: none;
}
.modal-text--small{
    font-size: 14px;
}
.modal-text.modal-text--bottom a {
    line-height: 0.8;
    display: inline-block;
    color: #6d6e71;
}
@media (max-width: 990px) {
    .modal-text--bottom{
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
    }

}
.modal-text--middle{
    text-align: center;
    font-size: 14px;
}
.modal-text--large-center{
    text-align: center;
    font-size: 16px;
}
.modal-text a{
    color: #f15f21;
    display: inline-block;
    line-height: 0.9;
}
.modal-text p:first-child{
    margin-top: 0;
}
.modal-text p:last-child{
    margin-bottom: 0;
}
.modal-change-password-success .modal-text:first-child{
    margin-bottom: 40px;

}
.modal-change-password-success .modal-text:last-child{
    margin-top: 20px;

}



/*form*/
.password-wrap{
    position: relative;
    margin-bottom: 20px;
}
.password-wrap__handle-click{
    position: absolute; top: 0; right: 0;
    height: 100%;
    width: 60px;
    cursor: pointer;
    z-index: 1;
}
.input-field{
    margin-bottom: 20px;
}
.input-field:last-child{
    margin-bottom: 0;
}
.text-field input, .btn-field input, .text-field textarea{
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    box-sizing: border-box;
    width: 100%;
    font-family: 'Circe';
    font-size: 16px;
    color: #6d6e71;
    text-overflow: ellipsis;
}
.text-field input[readonly] {
	cursor:default;
}
.text-field textarea{
    padding: 30px 20px;
    height: 130px;
    resize: none;
    overflow: auto;
}
.text-field{
    background: #f2f2f2;
    position: relative;
    transition: 300ms;
}
.text-field.error{
    background: #fde7de;
    /*box-shadow: inset 0 0 0 2px #a74014;*/
}

.text-field:before{
    position: absolute;
    top: 50%;
    right: 23px;
    transform: translateY(-50%);
}
.text-field input{
    padding: 19px 60px 18px 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}
.error input:-webkit-autofill,
.error input:-webkit-autofill:hover, 
.error input:-webkit-autofill:focus, 
.error input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #fde7de inset !important;
}
.text-field label span{
    position: absolute;
    top: 3px;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px 0 17px 0;
    left: 20px;
    transition: 300ms;
    transform-origin: left top;
    pointer-events: none;
}
.text-field.focus span {
    -webkit-transform: translateY(-4px) scale(.7);
    -ms-transform: translateY(-4px) scale(.7);
    -o-transform: translateY(-4px) scale(.7);
    transform: translateY(-4px) scale(.7);
    opacity: .3;
}
.text-field.focus input{
    padding-top: 28px;
    padding-bottom: 9px;
}
@media (max-width: 990px) {
    .check-field{
        width: calc(50% - 15px);
        margin-bottom: 25px !important;
    }
}
.check-field label{
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.check-field input{
/*    position: absolute;
    left: -999999px;*/
}
.check-field i{
    width: 30px;
    height: 30px;
    min-width: 30px;
    position: relative;
    background: #f2f2f2;
    margin-right: 10px;
}
.check-field .error i{
    background: #fde7de;
}
.check-field a{display: inline-block; line-height: 1;}
.check-field-policy{font-size: 14px;}
.profile-page-form .check-field-policy{width: 100%;}
@media (max-width: 990px) {
    .check-field i{
        width: 45px;
        height: 45px;
        min-width: 45px;
    }
}
.check-field i:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #f15f21;
    height: 14px;
    width: 14px;
    opacity: 0;
    transition: 600ms;
}
.check-field input:checked + i:after{
    opacity: 1;
}

.select-field{
    background: #f2f2f2;
    position: relative;
    transition: 300ms;
    height: 60px;
}
.select-field span{
    position: absolute;
    top: 50%;
    left: 0;
    transition: 300ms;
    pointer-events: none;
    display: none;
}
.select-field select{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 20px;
    padding-right: 60px;
    border: none;
    box-shadow: none;
    background: none;
    font-size: 16px;
    font-family: inherit;
    color: inherit;
}
.select-field select::-ms-expand {
    display: none;
}
.select-field:before{
    content: '';
    width: 60px;
    height: 100%;
    position: absolute;
    background: #f2f2f2;
    pointer-events: none;
    right: 0;
    top: 0;
    pointer-events: none;
    transition: 300ms;
}
.select-field:after{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 60px;
    line-height: 60px;
    text-align: center;
    pointer-events: none;
}
.select-field.error, .select-field.error:before{
    background: #fde7de;
}


.input-field--readonly{
    font-size: 15px;
    margin-bottom: 18px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    position: relative;
}
.readonly-body{
    font-size: 15px;
    font-weight: bold;
    width: 50%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    width: calc(100% - 110px);
}
.readonly-body span{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
.readonly-title{
    width: 110px;
    min-width: 110px;
}
.change-email{
    width: 50px;
    align-items: center;
    justify-content: center;
    color: #f15f21;
    cursor: pointer;
    position: relative;
    margin-left: 5px;
}
.change-email:after{
    position: absolute;
    top: -20px;
    bottom: -20px;
    content: '';
    cursor: pointer;
    width: 100%;
}
.change-email:before{
    transition: 300ms;
}
.change-email:hover:before{
    font-size: 16px;
}
.input-field--readonly .change-email{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}

.field-title{
    margin-bottom: 18px;
}
.field-double{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
/*.field-double > div{
    min-width: calc(50% - 15px);
}*/
.field-double > div:first-child{
    margin-right: 15px;
}
.field-double > div:last-child{
    margin-left: 15px;
}
.field-double--sms{
    align-items: center;
}
.field-double--sms > div:last-child{
    margin-left: 0;
    margin-bottom: 20px;
    /*min-width: 125px*/
}
.sms-pending .sms-link{opacity: .7}
.sms-link{
    color: #f15f22;
    cursor: pointer;
    padding-left: 20px;
}
.sms-link span{
    border-bottom: 1px solid #f15f22;
    transition: 300ms;
}
.sms-link:hover span{
    border-bottom-color: transparent;
}
.input-field-sms > div{
    position: relative;
    top: -10px;
    cursor: pointer;
}
.btn-field input, .btn {
    padding: 22px 5px 16px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Circe';
    font-size: 16px;
    font-weight: 600;
    color: #f15f22;
    border: 3px solid #f15f22;
    cursor: pointer;
    line-height: 1;
    transition: 600ms;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn2{background: #f15f22; color: #fff;}
.btn-field{
    position: relative;
}
button.btn-field{
    padding: 0;
    margin: 0;
    width: 100%;
    background: none;
    outline: none;
    border: none;
}
button.btn-field.input-field{
    margin-bottom: 20px;
}
.btn-field--loading{
    cursor: default;
    opacity: .6;
    pointer-events: none;
}
.btn-field--loading .btn {
    transition: 600ms opacity;
}
.btn-field:after{
    position: absolute;
    top: 50%;
    right: 10px;
    height: 20px;
    width: 20px;
    content: '';
    background: url(/images/loading.gif);
    background-size: contain;
    transform: translateY(-50%);
    display: none;
}
.btn-field--loading:after{display: block;}

@media (max-width: 370px) {
    .btn-field input, .btn{
        font-size: 13px;
    }
}
@media (min-width: 768px) {
    .btn-field:hover input, .btn:hover{
        background-color: #f15f21;
        color: #fff;
    }
    .btn2:hover{
        background-color: #fff;
        color: #f15f21;
    }
}
.btn [class^="ico-"]{
    margin-right: 10px;
    font-size: 20px;
}
.btn-link{
    padding: 22px 18px 18px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Circe';
    font-size: 16px;
    font-weight: 600;
    color: #6d6e71;
    border: 2px solid transparent;
    cursor: pointer;
}
.btn-link span{
    border-bottom: 1px solid;
    display: inline-block;
    line-height: 1;
    transition: 300ms;
}
@media (min-width: 768px) {
    .btn-link:hover span{
        border-bottom-color: transparent;
    }
}
.btn-field + .btn-link{
    margin-top: -17px;
}
.crm_loader{
    display: flex;
    align-items: center;
}
.crm_loader img{width: 20px; margin-left: 20px;}
@media (max-width: 768px) {
    .crm_loader{
        flex-direction: column;
        text-align: center;
    }
    .crm_loader img{margin-left: 0;margin-top: 20px;}
}
.form-links{
    padding-top: 15px;
    text-align: center;
    line-height: 1;
}
.form-links__item{
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 23px;
}
.form-links__item:last-child{
    margin-bottom: 0;
}
.form-links__item a {
    display: inline-block;
    line-height: 1;
}
/*
.form-links__item a span:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    background-color: #000;
}
 */



/*inner*/

.content{
    margin: 0 auto;
    width: 100%;
    max-width: 1130px;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
}
@media (max-width: 370px) {
    .content{
        padding-left: 18px;
        padding-right: 18px;
    }
}
.title{
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}
@media (max-width: 990px) {
    .title{
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 35px;
        font-size: 32px;
        text-align: center;
    }

}
@media (max-width: 370px) {
    .title{
        font-size: 28px;
    }
}
.title__link{
    color: #f15f22;
    font-size: 16px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    line-height: 22px;
    cursor: pointer;
    border-bottom: none;
    position: relative;
}
.title__link.disabled  {
    cursor: default;
}
.title__link.disabled span, .title__link.disabled:before{
    opacity: .5;
}
.tooltip{
    position: absolute;
    background: #fff;
    border: 1px solid rgba(0,0,0,.1);
    top: 100%;
    margin-top: 5px;
    left: 0;
    /*width: 100%;*/
    color: #000;
    font-weight: normal;
    font-size: 12px;
    line-height: 1;
    padding: 15px;
    box-sizing: border-box;
    display: none;
    z-index: 100;
    text-transform: none;
}
.title__link:hover .tooltip{display: block;}
@media (max-width: 990px) {
    .title__link{
        margin-top: 8px;
    }
}
@media (max-width: 370px) {
    .title__link{
        font-size: 14px
    }
}
.title__link:before{
    margin-right: 12px;
    transition: 600ms;
}
.title__link span{
    padding-top: 4px;
    transition: 600ms;
    border-bottom: 1px solid transparent;
    display: inline-block;
    line-height: 1;
    text-transform: uppercase;
}
.title__link.ico-13:before{
    font-size: 17px;
    margin-right: 9px;
}
.title__link.ico-13.animated:before{
    animation: icoanim .5s linear
}
@keyframes icoanim {
    100% {transform: rotate(360deg); }
}
@media (min-width: 768px) {
    .title__link:hover span{
        border-bottom-color: #f15f22;
    }
}
.header {
    height: 103px;
    align-items: center;
    justify-content: space-between;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-bottom: 70px;
}
@media (max-width: 990px) {
    .header{
        justify-content: center;
        height: 135px;
        margin-bottom: 0;
    }

}
.logo{
    height: 70px;
    border: none;
    text-decoration: none;
}
.logo:before{
    font-size: 70px;
}

.header-icons{
    display: flex;
}
.header-icons--mobile{
    display: none;
}
@media (max-width: 990px) {
    .header-icons--desctope{
        display: none;
    }
    .header-icons--mobile{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(255,255,255,.9);
        height: 75px;
        z-index: 100;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 25px rgba(0,0,0,.22);
        transition: 1000ms;
    }
    .header-icons--mobile.scrolled{
        opacity: 0;
        pointer-events: none;
    }
}
.header-icons__icon{
    position: relative;
    border-bottom: none;
}
.header-icons__icon .ico-logout:before{font-size: 16px}
.header-icons__icon i, .header-link i{
    position: absolute;
    top: 0;
    right: 0;
    height: 17px;
    line-height: 17px;
    padding-left: 3px;
    padding-right: 3px;
    min-width: 11px;
    border-radius: 50%;
    font-size: 9px;
    background: #6d6e71;
    -webkit-transform: translate(40%, -30%);
    -ms-transform: translate(40%, -30%);
    -o-transform: translate(40%, -30%);
    transform: translate(40%, -30%);
    text-align: center;
    color: #fff;
    font-style: normal;
    transition: 600ms;
}


.header-icons__icon i.animate-added{
    -webkit-transform: scale(1.3) translate(40%, -30%);
    -ms-transform: scale(1.3) translate(40%, -30%);
    -o-transform: scale(1.3) translate(40%, -30%);
    transform: scale(1.3) translate(40%, -30%);
}
.header-icons__icon > div{
    overflow: hidden;
    width: 35px;
    border-radius: 35px;
    background: #f15f21;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #fff;
    transition: 600ms;
    cursor: pointer;
    padding-right: 15px;
    box-sizing: border-box;
    text-transform: uppercase;
    margin-left: 15px;
}
@media (max-width: 990px) {
    .header-icons__icon{
        margin-left: 15px;
        margin-right: 15px;
    }
    .header-icons__icon > div{
        margin-left: 0;
    }
}
.header-icons__icon > div.ico-faq{
    background: #6d6e71;
}
.header-icons__icon span{
    font-weight: bold;
    font-size: 11px;
    line-height: 1;
    padding-top: 2px;
}
.header-icons__icon > div:before{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    min-width: 35px;
    height: 35px;
    line-height: 35px;
}
@media (min-width: 990px) {
    .header-icons__icon:hover > div{
        width: auto;
    }
    .header-icons__icon:hover > .ico-faq{
        width: 165px;
    }
    .header-icons__icon:hover > .ico-profile{
        width: 107px;
    }
    .header-icons__icon:hover > .ico-fav{
        width: 118px;
    }
    .header-icons__icon:hover > .ico-lock{
        width: 96px;
    }
    .header-icons__icon:hover > .ico-logout{
        width: 91px;
    }
}

.header-links{
    max-width: 604px;
    justify-content: space-between;
    width: 55%;
    display: flex;
}
.header-link{
    position: relative;
    border-bottom: none;
    cursor: pointer;
}
.header-link i{background: #f15f21; right: -16px;}
.header-link{
    font-size: 16px;
    font-weight: 600;
}
.header-link span{
    border-bottom: 3px solid transparent;
    padding-bottom: 2px;
    transition: 600ms;
}

.header-link.active span, .header-link:hover span{
    border-bottom-color: #f15f21
}
.footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: #6d6e71;
    color: #fff;
    font-size: 14px;
}
.footer a{
    color: #fff;
}
.footer-logo{
    margin-right: 35px;
}
.footer-logo:before{
    font-size: 35px;
}
.footer .content{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
}
.middle{
    padding-bottom: 145px;
}
.footer .content > div{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
}
@media (max-width: 650px) {
    .footer-copy{
        position: absolute;
        right: 30px;
        top: 33px;
    }
    .footer-link{
        position: absolute;
        right: 30px;
        top: 51px;
    }

}
@media (max-width: 370px) {
    .footer{
        font-size: 12px;
    }
    .footer-copy, .footer-link{
        right: 18px;
    }
}

/* table*/


.table{
    width: calc(100vw - 40px);
    max-width: 1080px;
}
.table-title{
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
}
table{
    padding: 0;
    margin: 0 0 50px;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /*display: block;*/
}
table tbody, table thead{
    width: 100%;
}
td{
    padding: 10px 17px;
    transition: background 1000ms;
}
tr.sep {
    pointer-events: none;
}
tr.sep td{
    padding-top: 5px;
}

@media (max-width: 990px) {
    tr.sep td{
    }
}
tr:hover td{
    background: #f2f2f2;
}
th {
    padding: 22px 14px;
    background: #6d6e71;
    color: #fff;
    font-weight: 600;
}

/*@media (max-width: 1120px) {
  td, th{
    padding-left: 10px;
    padding-right: 10px;
  }
}*/

td > a{
    border: none;
}
.cell-price{
    color: #f15f21;
    white-space: nowrap;
}
.cell-text > div{
    max-width: 300px
}
.deals .cell-text > div{
    width: 220px;
}
.cell-text {
   padding-left: 0;
}
.cell-text1 {
    width: 150px;
    text-align: center;
}
td > div > a{
    color: #f15f21;
    display: inline-block;
    line-height: 1;
}
.cell-num{
    text-align: center;
}
@media (max-width: 990px) {
    .cell-num {
        padding-top: 0 !important;
        text-align: left;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 16px;
    }
}
.cell-img, .title-img{
    padding-left: 0;
    text-align: left;
}
.title-img {
    width: 120px;
}
.cell__title.mobile {
    min-width: 85px;
}
.cell-img img{
    max-width: 100px;
    /*background: #f2f2f2;*/
}
@media (max-width: 990px) {
    .cell-img {
        order: -1;
    }
    .cell-img img{
        background: #fff
    }
}

.title-text, .cell-text{
    text-align: left;
    padding-right: 0;
}
.title-text2, .cell-text2{
    text-align: center;
}
@media (max-width: 990px) {
    .cell-text2{
        text-align: left;
        padding-bottom: 30px;
        padding-top: 0 !important;
    }
    .cell-text1{
        padding-top: 0 !important;
        padding-bottom: 30px !important;
        width: auto;
        text-align: left;
    }
}
.cell-text2__info{
    display: block;
    font-size: 14px;
    min-width: 140px;
    padding-top: 5px;
}
@media (max-width: 990px) {
    .cell-text2__info{
        font-size: 12px;
    }
}
.cell-price, .cell-icon, .cell-square{
    text-align: center;
}
.cell-icon span{
    display: inline-block;
    background: #6d6e71;
    color: #fff;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    position: relative;
    transition: 600ms;
    cursor: pointer;
}
@media (max-width: 990px) {
    .cell-icon span{
        height: 33px;
        width: 33px;
    }
}

.cell-icon--disabled{
    pointer-events: none;
}

.cell-icon span.ico-lock--disabled{
    background: #d3d3d4;
}
.cell-icon span:before {
    position: absolute;
    top: 50%;
    left: 50%;
    line-height: 1;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.cell-icon span.ico-lock:before{
    font-size: 19px;
}
.cell-icon span.ico-download:before{
    font-size: 14px;
}
.cell-icon span.ico-close:before{
    font-size: 12px;
}
.cell-icon span.ico-ok{
    background: none;
    color: #6d6e71;
    font-size: 30px;
    cursor: default;
}
@media (min-width: 678px) {
    .cell-icon span:hover{
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
        background: #f15f21;
    }
    .cell-icon span.ico-ok:hover{
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        background: transparent;
    }
}
.table-info{
    margin-bottom: 50px;
    color: #f15f21;
}

@media (max-width: 990px) {
    .table {
        position: relative;
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
        font-size: 14px;
    }
    .table td{
        background: #f2f2f2
    }
    .table thead{
        display: none;
    }
    .table table, .table tbody, .table tr, .table td{
        display: block;
    }
    .table tr{
        margin-bottom: 40px;
        position: relative;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
    }
    .cell-icon, .cell-icon a{
        text-decoration: none;
        border: none;
     /*   position: absolute;
        bottom: 102px;
        right: 25px;
        padding: 0 !important;*/
    }
    .make-reservation .cell-text{
        min-height: 80px;
    }
    .cell-icon--book{
        bottom: 125px;
    }
    .deals .cell-icon{
        bottom: 181px;
    }
    .cell-icon + .cell-icon {
        bottom: 62px;
    }
    .cell-icon + .cell-icon + .cell-icon{
        bottom: 22px;
    }
    .cell-text{
        padding: 0 20px 20px !important;
        line-height: 1.4;
    }
    .cell-text > div{
        width: auto;
        padding-right: 40px;
    }
    .cell-square, .cell-price{
        text-align: left;
        padding: 0 20px 5px !important;
    }
    .cell-price{
        padding-bottom: 25px !important;
        font-weight: 600;
    }
    .cell-img{
        padding: 25px 20px !important;
    }
    .cell-img img{
        width: 100%;
        max-width: 100%;
    }
    .cell__title{
        font-weight: bold;
        padding-right: 10px;
        display: inline-block;
    }
    .cell-type{
       /* position: absolute;
        right:25px;
        bottom: 220px;
        padding: 0;*/
    }
    .no-active .cell-type, .no-active .cell-icon{
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
    }
    .cell-img{position: relative;}
    .row-icons-mobile{
        position: absolute;
        top: 100%;
        right: 20px;
        display: flex;
        flex-direction: column;
    }
}

.form-title{
    white-space: nowrap;
    margin-bottom: 26px;
    text-transform: uppercase;
    font-weight: 600;
}
.make-reservation .cell-price {
    font-weight: bold;
}
.make-reservation .form-title {
    font-size: 20px;
}

.profile-page-form {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    margin-left: -4%;
    /*justify-content: space-between;*/
}
.profile-page-form__column{
    width: calc(100% / 3 - 4%);
    margin-bottom: 40px;
    margin-left: 4%;
}
@media (max-width: 990px) {
    .form-title{
        font-size: 24px;
        white-space: normal;
        text-align: center;
        margin-bottom: 30px;
    }
    .profile-page-form {
        margin-left: 0;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
    }
    .profile-page-form__column{
        width: 100%;
        margin-left: 0;
        max-width: 350px;
        margin: 0 auto 20px;
    }
    .profile-page .profile-page-form__column{
        margin-bottom: 40px;
    }
    .profile-page .profile-page-form__column:last-child{
        margin-bottom: 0;
    }
}
@media (max-width: 370px) {
    .form-title{
        font-size: 22px;
    }
}
.profile-text{
    padding-top: 15px;
    font-size: 14px;
}
.profile-text a{border-bottom: none;color: #f15f21;}
.profile-text span{
    color: #f15f21;
    font-weight: 600;
    transition: 300ms;
    border-bottom: 1px solid transparent;
    line-height: 1;
    display: inline-block;
}
.profile-text a:hover span{
    border-bottom-color: #f15f21;
}
.mobile{
    display: none;
}
@media (max-width: 990px) {
    .mobile{
        display: block;
    }
    span.mobile{
        display: inline-block;
    }
    .desctope{
        display: none;
    }

}
.modal-profile-form .modal__body{
    margin-top: 30px;
}

.deal-status{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
}
@media (max-width: 990px) {
    .deal-status{
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        max-width: 350px;
        margin: 0 auto 25px;
    }
    .deal-status > div{
        margin-bottom: 15px;
    }

}
.deal-status>span {
    display: block;
    border: none;
    cursor: pointer;
    transition: 300ms color;
    margin-bottom: 3px;
}
.deal-status>span.active {
    color: #f15f21;
    pointer-events: none;
    font-weight: 600;
}
.deal-status>span:hover {
    color: #f15f21;
}

.deal-status i{
    position: relative;
    display: inline-block;
    margin-left: 10px;
    top: -10px;
    height: 17px;
    line-height: 19px;
    padding-left: 3px;
    padding-right: 3px;
    min-width: 11px;
    border-radius: 50%;
    font-size: 9px;
    background: #f15f21;
    text-align: center;
    color: #fff;
    font-style: normal;

}

.deal .title .title__link, .deals .title .title__link{
    margin-top: 5px;
    margin-bottom: 8px;
}
/*.deal .title .title__link:first-child, .deals .title .title__link:first-child{
    margin-bottom: 0;
}*/
.deal .title, .deals .title{
    align-items: flex-start;
    line-height: 1;
    margin-bottom: 0;
    /*text-transform: none;*/
}
@media (max-width: 990px) {
    .deal .title{
        align-items: center;
        margin-bottom: 40px;
        text-align: center;
    }

    .deal .title .title__link{
        margin-top: 0;
    }
    .deal .title .title__link:first-child{
        margin-bottom: 5px;
    }
}
.deal-path{
    margin-bottom: 60px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
.deal-path.mobile{
    display: none;
}
@media (max-width: 990px) {
    .deal-path.mobile{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        font-size: 16px;
        font-weight: normal;
        justify-content: center;
        width: 100%;
        padding-top: 25px;
        margin-bottom: 25px;
    }
    .deal-path.desctope{
        display: none;
    }
}
.deal-path a{
    color: #f15f21;
    /*margin-left: 20px;*/
}

.deal-steps{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
@media (max-width: 990px) {
    .deal-steps{
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
    }
}
.deal-steps > div{
    /*  width: calc(12.5% + 2px);
      min-width: 12.5%;*/
    width: 20%;
    margin-left: -3px;
}
@media (max-width: 990px) {
    .deal-steps > div{
        width: 100%;
        margin-left: 0;
        position: relative;
        margin-bottom: -3px;
    }
}
.deal-steps__title{
    border: 3px solid #6d6e71;
    display: block;
    padding: 13px 15px;
    font-size: 14px;
    /*white-space: nowrap;*/
    height: 65px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
}
@media (max-width: 990px) {
    .deal-steps__title {
        padding-bottom: 25px;
    }
    .deal-steps__title br{
        display: none;
    }
}
.deal-steps > div.active{
    position: relative;
    z-index: 1;
}
.deal-steps > div.active ~ div .deal-steps__title{
    border-color: #e6e6e6;
    color: #cccccc;
}
.deal-steps > div.active .deal-steps__title{
    color: #fff;
    border-color: #f15f21;
    background: #f15f21;
}
.deal-steps__date{
    margin-top: 10px;
    display: block;
    font-size: 14px;
    text-align: center;
}
@media (max-width: 990px) {
    .deal-steps__date{
        margin: 0;
        bottom: 12px;
        left: 0;
        position: absolute;
        left: 18px;
        font-size: 10px;
    }
}
.deal-steps > div.active .deal-steps__date{
    color: #f15f21;
    font-weight: 600;
}
@media (max-width: 990px) {
    .deal-steps > div.active .deal-steps__date{
        color: #fff;
        font-weight: normal;
    }
}

.deal__flat{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    padding-top: 35px;
    padding-bottom: 50px;
}

@media (max-width: 990px) {
    .deal__flat{
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        padding-top: 50px;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
}
.deal__flat__left{
    width: 35%;
    min-width: 35%;
    cursor: zoom-in;
}
@media (max-width: 990px) {
    .deal__flat__left{
        width: 100%;
        min-width: 100%;
        margin-bottom: 35px;
    }
}
.deal__flat__left img{
    width: 100%;
    background: #f2f2f2;
}
.deal__flat__right{
    width: 60%;
    min-width: 60%;
}
@media (max-width: 990px) {
    .deal__flat__right{
        width: 100%;
        min-width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
    }
}
.deal__flat__title{
    font-size: 20px;
    color: #f15f21;
    margin-bottom: 10px;
}
@media (max-width: 990px) {
    .deal__flat__title{
        font-weight: bold;
    }
}
@media (max-width: 370px) {
    .deal__flat__title{
        font-size: 18px;
    }
}
.deal__flat__text{
    margin-bottom: 60px;
}
@media (max-width: 990px) {
    .deal__flat__text{
        margin-bottom: 40px;
    }
}
.deal__flat__text > div{
    margin-bottom: 6px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
.deal__flat__text > div > span{
    width: 130px;
}
@media (max-width: 990px) {
    .deal__flat__text > div > span{
        font-weight: bold;
    }
}
.deal__flat .ico-download{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    border-bottom: none;
    align-items: center;
}
.deal__flat .ico-download:before {
    line-height: 1;
    font-size: 14px;
    background: #6d6e71;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    min-width: 35px;
    line-height: 35px;
    color: #fff;
    text-align: center;
    margin-right: 15px;
}
.deal__flat .ico-download span{
    border-bottom: 1px solid;
    transition: 300ms;
    line-height: 1;
    display: inline-block;
}
.deal__flat .ico-download:hover span{
    border-bottom-color: transparent;

}

.deal__zoom{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10;
    animation: deal__zoom ease-in .6s;
}

@keyframes deal__zoom {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.deal__zoom__close{
    height: 33px;
    width: 33px;
    background: url("/images/source/close_i.svg") #6d6e71 center center no-repeat;
    background-size: 11px;
    display: block;
    border-radius: 50%;
    color: transparent;
    border: none;
    right: 25px;
    top: 25px;
    position: absolute;
    cursor: pointer;
}
.deal__zoom > img{
    position: absolute;
    top: 50%; left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
}


.tab-nav{
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    transition: 300ms;
}
.active .tab-nav, .tab-nav:hover{
    color: #f15f22;
}

.tabs{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
}
@media (max-width: 990px) {
    .tabs{
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
    }
}
.tabs__lef{
    width: 255px;
    min-width: 255px;
}
.tabs__lef a{
    border: none;
}
@media (max-width: 990px) {
    .tabs__lef{
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }
}
.faq-btn{
    margin-top: 60px;
}
@media (max-width: 990px) {
    .faq-btn{
        width: 100%;
        box-sizing: border-box;
        margin-top: 30px;
    }
}
.tabs__right{
    width: calc(92% - 255px);
    min-width: calc(92% - 255px);
}
@media (max-width: 990px) {
    .tabs__right{
        width: 100%;
        min-width: 100%;
        padding-top: 45px;
    }
}
.question{
    padding-bottom: 25px;
    font-size: 20px;
    font-weight: 300;
    color: #f15f22;
    padding-left: 34px;
    position: relative;
    cursor: pointer;
}
@media (max-width: 990px) {
    .question{
        font-size: 18px;
    }
}
.question span{
    font-weight: 600;
    border-bottom: 1px solid;
    line-height: 1.3;
    transition: 300ms;
}
@media (min-width: 990px) {
    .question:hover span{
        border-bottom-color: transparent;
    }
}
.question.active{
    color: #6d6e71;
}
.answer{
    padding-left: 75px;
    padding-top: 30px;
    padding-bottom: 60px;
}
@media (max-width: 990px) {
    .answer{
        padding-left: 34px;
        padding-bottom: 50px;
        padding-top: 0;
    }
}
.answer p{
    margin: 1.5em 0;
}
.answer p:last-child{
    margin-bottom: 0;
}
.answer p:first-child{
    margin-top: 0;
}
.question-ico, .question-ico-active{
    position: absolute;
    left: 0;
    top: 4px;
}
.question-ico{
    font-size: 15px;
    opacity: 1;
    transition: 300ms;
}
.question-ico-active{
    font-size: 3px;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    -o-transform: translateY(6px);
    transform: translateY(6px);
}
.question.active .question-ico {
    opacity: 0;
}

.deals .table tbody tr {
    cursor: pointer;
}

.title-square small {
    font-size: 8px;
}

.nowrap {
    white-space: nowrap;
}

@media (max-width: 1200px) {
    .deals .cell-text > div{
        width: 150px;
    }
}

@media (max-width: 990px) {

    .cell-text2 .cell__title.mobile {
        display: none;
    }
    .close-icon-row{
        height: 33px;
        width: 33px;
        background: url("/images/source/close_i.svg") #6d6e71 center center no-repeat;
        background-size: 11px;
        display: block;
        border-radius: 50%;
        color: transparent;
        border: none;
        right: 25px;
    }
    td {
        padding: 10px 20px;
    }
    .deal-status i {
        top: 0;
    }
    .table tr> .cell-type,  .table .cell-text2{
        display: none;
    }

}

@media (max-width: 405px) {

    .form-links__item-auth--withbr {
        border: none;
        display: inline;
    }
    .form-links__item-auth--withbr span {
        display: inline-block;
        border-bottom: solid 1px;
    }

}


@media (max-width: 390px) {
    .modal-text--bottom {
        padding-left: 0;
        padding-right: 0;
    }
}



@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 80px, 0);
            transform: translate3d(0, 80px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

.make-reservation .readonly-title{
    width: 140px;
}
.make-reservation__profile{
    width: 350px;
    margin-bottom: 40px;
}
.make-reservation__profile .profile-page-form__column{
    margin-left: 0;
    width: 100%;
    margin-bottom: 30px;
}
@media (max-width: 990px) {
    .make-reservation__profile{
        margin: 0 auto 40px;
        max-width: 100%;
    }
}
@media (max-width: 350px) {
    .make-reservation__profile{
        width: 100%;
        margin: 0 auto 40px;
    }
    .make-reservation .readonly-title{
        width: 120px;
    }
}

.loading{
    margin-bottom: 40px;
}

.text1__loading{
    position: relative;
}
@media (min-width: 991px) {
   
    td.cell-text2 > div > a{
        position: relative;
    }
    .loading-icon{
        height: 10px;
        width: 10px;
        display: inline-block;
        background: url(/images/loading.gif);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -15px;
        top: 1px;
    } 
    .text1__loading .loading-icon{top: 6px;}
}

.text-page{
    line-height: 1.7;
}
.text-page .title{line-height: 1;}


.error-page{
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 4vw;
    box-sizing: border-box;
}

.content2{
    margin: 0 auto;
    width: 100%;
    padding: 0 5.6vw;
    /*max-width: 1520px;*/
    box-sizing: border-box;
}
.error-page .header{
    height: auto;
    padding: 4vw 5.6vw 0;
}
.error-page__body{
    text-align: center;
    font-size: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.error-page__body a{
    display: inline-block;
    line-height: 1;
    color: #f15f21;
}
.error-page__body img{
    max-width: 100%;
    max-height: 40vh;
    margin-bottom: 5vh;
}
@media (max-width: 1450px) {
    .error-page__body{font-size: 17px;}
}
@media (max-width: 768px) {
    .error-page .header{
        padding: 40px 30px;
    }
    .content2{
        padding: 0 30px;
    }
    .error-page__body {
        font-size: 16px;
    }
}
@media (max-width: 370px) {
    .content2{
        padding-left: 18px;
        padding-right: 18px;
    }
}

.modal__body.js-ask-question {
    padding: 0;
}
.modal__body.js-ask-question .modal-close {
    top:-22px;
    right:-72px;
}
.popupTabs {

}
.popupTabs__tabs {
    display: flex;
}
.popupTabs__tab {
	position:relative;
	display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 70px;
    background: #f2f2f2;
    font-size: 16px;
    font-weight: 600;
    color: #f15f22;
    text-align: center;
    padding: 5px 10px 0;
    text-transform: uppercase;
    cursor: pointer;
}
.popupTabs__tab_active {
    background: #fff;
    cursor: default;
}
.popupTabs__tab_disabled {
	cursor:default;
}
.popupTabs__tab span {
	background:#fff;
	position:absolute;
	top:100%;
	left:0;
	right:0;
	padding: 10px;
	font-size: 12px;
	text-transform:none;
	color: #6d6e71;
    font-weight: 400;
	box-shadow: 0px 0px 15px 0px rgba(50, 50, 50, 0.2);
	opacity:0;
	transform:translateY(20px);
	visibility:hidden;
	transition: opacity 600ms, transform 600ms, visibility 600ms;
}
.popupTabs__tab span:after {
	content:'';
	position:absolute;
	border: solid 6px transparent;
	border-bottom-color: #fff;
	top: -12px;
	left:50%;
	margin-left:-6px;
}
.popupTabs__tab:hover span {
	opacity:1;
	transform:translateY(0);
	visibility:visible;
}
.popupTabs__content {
    padding: 80px 35px 65px;
}
.popupTabs__content iframe {
    border: none;
    width: 100%;
    height: 1155px;
}
.add-email {
	position:relative;
}
.add-email__icon {
	position: absolute;
	width: 60px;
	height:60px;
	top: -80px;
	right:-80px;
	background: #f2f2f2;
	line-height:66px;
	text-align:center;
	font-size:30px;
	cursor:pointer;
}
.add-email__input {
	height:0;
	overflow:hidden;
	transition: height 600ms;
}
.add-email__input.visible {
	margin-bottom:20px;
	height:60px;
}

.file-drop {
    position: relative;
    height: 100px;
    margin-bottom: 20px;
	background: #f2f2f2;
}
.file-drop.error {
	background: #fde7de;
}

.file-drop > .file-drop-target {
    position: absolute;
    top: 0;
    left: 0;
    right:0;
	bottom: 0;
    border: dashed 3px #d8d8d8;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
    background-color: #f2f2f2;
    z-index: 50;
    opacity: 1;

    /* typography */
    color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
    color: #f15f22;
    border-color: #f15f22;
}
.file-drop input {
	position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
}

.cookie_message {
    z-index: 999;
    position: fixed;
    bottom: 0px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 3px 0px;
    text-align: center;
    background: rgba(245, 245, 245, 0.9);
    font-size: 14px;
    padding: 10px 10%;
    box-sizing: border-box;
}
.cookie_message a{display: inline-block; line-height: 1; color: #f15f22}
.cookie_message__close {
    position: absolute;
    top: 20px;
    right: 3%;
    font-size: 15px;
    cursor: pointer;
}

.modal-ppo .modal__container{
    width: 750px;
}
.modal-ppo .modal__body{
    margin-top: 30px;
    padding: 50px 30px
}
.modal-ppo.modal-ask-question .modal__body{
    padding: 0;
}
.modal-ppo .modal-title{
    margin-bottom: 20px;
}
.ppo-form__btn{
    width: 50%;
    margin: 20px auto 0;
}
.ppo-form{
    display: flex;
    justify-content: space-between;
}
.ppo-form__column{
    width: 48%;
}
input:focus , select:focus {
    color: #000;
}
.btn-field{transition: 300ms;}
.btn-field:focus{
    transform: scale(1.02);
}
.ppo-form .text-field textarea{height: 135px;}
.ppo-form--btn{
    align-items: center;
    padding-top: 20px;
}
.ppo-form .check-field{width: 100%; margin-bottom: 0;}
.ppo-form button.btn-field.input-field{margin-bottom: 0;}
.check-field span{transition: 300ms;}
.ppo-form [type="checkbox"]:focus ~ span, [type="checkbox"]:focus ~ span a{color: #000;}
.deal-btn{max-width: 350px; cursor: pointer;}
@media (max-width: 768px) {
    .ppo-form{
        flex-direction: column;
    }
    .ppo-form__column{
        width: 100%;
    }
    .popupTabs__content{padding: 30px 20px;}
    .popupTabs__tab{font-size: 12px;}
    .popupTabs__content .modal-title{font-size: 15px;}
    .popupTabs__content .form-content{padding: 0;}
    .popupTabs__content .form-content > .ico-email {width: calc(100% - 79px)}
    .add-email__icon{right: 0;}
}
