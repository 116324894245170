@media (min-width: 991px) {
  .header2{
    margin-bottom: 0;
    height: 160px;
  }
  .mail .title{
    position: absolute; top: 0; left: 0;
    width: 100%; height: 160px;
    display: flex; align-items: center; justify-content: center;
    transform: translateY(-100%);
  }
}
.content.header2, .content.mail{
  max-width: 1520px;
}
.mail .table {width: calc(100vw - 50px); max-width: 100%; }
.text-search{
  position: relative;
  height: 60px;
  margin-bottom: 40px;
}
.field-text{
  position: relative;
  height: 60px;
  width: 
}
.text-search input, .field-text input{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  background: #f2f2f2;
  color: #6d6e71;
  font-size: 16px;
  width: calc(100% - 180px);
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-family: inherit;
}
.field-text input{
  width: 100%;
}
.text-search__btn{
  position: absolute;
  top: 0;
  right: 0;
  width: 169px;
  box-sizing: border-box;
}

.checkbox-fields{
  display: flex;
}
.checkbox-fields .check-field{
  margin-right: 50px;
  margin-bottom: 30px !important;
}

.filter__row{
  display: flex;
}
.filter-field{
  margin-right: 10px;
  width: 30%;
  margin-bottom: 30px;
}
.filter-field:last-child{margin-right: 0;}
.filter-field__title{
  font-size: 12px;
  margin-bottom: 8px;
}

.filter-field__body{
  display: flex;
  position: relative;
}
.filter-field--from-to:after{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 1px;
  background: #b3b3b3;
}
.filter-field__body .select-field{
  width: 100%;
  min-width: 100%;
}
.filter-field--from-to .select-field{
  width: 50%;
  min-width: 50%;
}
.filter .select-field:after, .filter .select-field:before{
  width: 40px;
  font-size: 6px;
}
.filter .check-field input{
  position: absolute;
}
.filter .select-field select{
  padding-right: 40px;
  outline: none;
  font-size: 15px;
  padding-left: 15px;
}
.filter .input-field{margin-bottom: 0;}
.filter-field--client{
  width: calc(100% - 34% - 265px - 20px);
  min-width: calc(100% - 34% - 265px - 20px);
}
.filter-field--email{
  width: 35%;
}

.filter-field--large{
  width: 265px;
  min-width: 265px;
}
.filter-field--large2{
  width: 60%;
}
.filter-field--middle{
  width: 10%;
  min-width: 160px;
}
.filter-field--small{
  width: 4.7%;
  min-width: 72px;
}
.filter-field--btn{
  width: 20%;
  min-width: 169px;
}

.filter-field-radio{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 80px;
  padding-top: 40px;
}
.filter-field-radio > div{
  margin-right: 10px;
  width: 50%;
  text-align: center;
  box-sizing: border-box;
  border: 2px solid #e6e6e6;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  transition: 600ms;
  font-weight: bold;
}
.mail tr:hover td{background: #fcfcfc}
.mail .cell-img {padding-left: 17px;}
.mail th, .mail td{font-size: 14px;}
.mail td:first-child{white-space: nowrap;}
.cell-center{text-align: center;}
.mail .cell-img img{
  max-width: 70px;
}
.mail .cell-price{font-weight: bold;}


.mailing-send{
  display: flex;
  width: 100%;
  padding-top: 40px;
}
.mail table{margin-bottom: 10px;}
.modal-mail .input-field{
  margin-bottom: 0;
}
.modal-mail .modal-text{
  margin-bottom: 40px;
}
.modal-mail .modal-text span{
  color: #f15f21;
}
.modal-mail .modal__body{
  padding-top: 130px;
  padding-bottom: 90px;
}
@media (min-width: 1000px) {
  .filter-field-radio > div:hover{
    border-color: #f15f21
  }
}
.filter-field-radio > div.active{
  background: #f15f21;
  color: #fff;
  border-color: #f15f21;
}
.filter-field-radio > div:last-child{
  margin-right: 0;
}

@media (max-width: 1520px) {
  .mail th, .mail td{
    font-size: 13px; 
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 1430px) {

  .filter__row--last{
    flex-wrap: wrap;
  }
  .filter-field-radio > div{
    font-size: 15px;
  }
  
}

@media (max-width: 1200px) {
  .filter-field-radio > div{
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 990px) {
  .cell-center{text-align: left;}
  .mail th, .mail td{
    font-size: 15px; 
    padding-left: 20px;
    padding-right: 20px;
  }
  .mail td{
    display: flex;
  }
  .mail .table tbody{
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }
  .mail .table tr{
    width: 48%;
  }
  .mail .cell-img{
    justify-content: center;
  }
  .mail .cell-img img{
    max-width: 300px;
  }
  .filter-field{
    margin-bottom: 15px;
  }
  .mail td .check-field i{
    background: #fff;
  }
  .filter-field-radio{margin-bottom: 10px}
  .mail tr:hover td{background: #f2f2f2}
}
@media (max-width: 880px) {
  .filter__row{
    flex-wrap: wrap;
  }
  .filter-field--client, .filter-field--email{
    width: calc(100% - 260px - 20px);
    min-width: calc(100% - 260px - 20px);
  }
  .filter-field-radio{
    flex-direction: column;
  }
  .filter-field-radio > div{
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-field--btn{margin-bottom: 0;}
  .mailing-send{
    flex-direction: column;
  }
  .mailing-send {
    padding-top: 0
  }
  .mailing-send .filter-field{
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 720px) {
  .filter__row, .checkbox-fields{
    flex-wrap: wrap;
  }
  .filter-field{
    width: calc(100% - 10px);
    min-width: calc(100% - 10px);
  }
  .checkbox-fields .check-field{
    width: 50%;
    margin: 0;
    font-size: 14px;
  }
  .checkbox-fields .check-field i {
    width: 35px;
    height: 35px;
    min-width: 35px;
  }
  .filter-field--btn .filter-field__title{display: none;}
  .mail .table tr{
    width: 100%;
  }
  .text-search input{
    width: 100%;
    padding-right: 60px;
    text-overflow: ellipsis;
  }
  .text-search__btn{
    height: 60px;
    font-size: 0;
    border: none;
    background-image: url(/images/search.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 60px;

  }
} 